import { baseApi } from './baseApi'

const mediaApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        addMedia: builder.mutation({
            query: (formData) => ({
                method: 'POST',
                body: formData,
                url: `upload`,
            }),
        }),
    }),
})

export const { useAddMediaMutation } = mediaApi