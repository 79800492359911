import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useLoginMutation, useSignUpMutation } from "store/api/customersApi";
import { useDispatch } from "react-redux";
import { setUser } from "store/slices";
import { useForm } from "react-hook-form";
import { WooCommerceError } from "data/data";
import toast, { Toaster } from "react-hot-toast";
import { useVerifyAccountMutation } from "store/api/baseApi";
import VerifyCode from "containers/VerifyCode";
import CheckPassword from "components/CheckPassword";
import { useCollege } from "store/utils/hooks";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

type FormValues = {
  name: string,
  email: string,
  password: string,
  confirm_password:string,
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [signUp, res] = useSignUpMutation();
  const [verify, resVerify] = useVerifyAccountMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [idx, setIdx]=useState(0);
  const [email, setEmail] = useState('')
  const college =  useCollege()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm<FormValues>();

  const formPassword = watch('password')

  const isPending = res.isLoading;

  async function onSubmit(data: any) {
    if(idx === 0){
      const { name, email, password } = data;
      const res = await signUp({
        name,
        username:name,
        email,
        password,
        role: "customer",
        schools:[college?.id]
      });
      if(!res.error){
        setEmail(res.data.data.email)
        toast.success("Registro exitoso");
        setIdx(1)
      }else{
        setError("confirm_password", {
          message: (res.error as WooCommerceError).data.message,
        });
      }
    }else{
      const res = await verify({pin:data.code,email})
      if(!res.error){
        toast.success("Email verificado");
        localStorage.setItem("accessToken",res.data.data.accessToken)
        dispatch(setUser(res.data.data.user));
        navigate("/");
      }else{
        setError("code", {
          message: (res.error as WooCommerceError).data.message,
        });
      }
    }
  }

  const renderRegister = () => {
    return <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Nombre de usuario
              </span>
              <Input
                type="text"
                className="mt-1"
                error={errors.name?.message}
                {...register("name", {
                  required: "El nombre de usuario es requerido",
                })}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email
              </span>
              <Input
                type="email"
                placeholder="ejemplo@ejemplo.com"
                className="mt-1"
                error={errors.email?.message}
                {...register("email", {
                  required: "El email es requerido",
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Entre un email válido",
                  },
                })}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Contraseña
              </span>
              <Input
                type="password"
                className="mt-1"
                error={errors.password?.message}
                {...register("password", {
                  required: "La contraseña es requerida",
                })}
              />
            </label>
            <CheckPassword password={formPassword || ""}/>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Repetir Contraseña
              </span>
              <Input
                type="password"
                className="mt-1"
                error={errors.confirm_password?.message}
                {...register("confirm_password", {
                  required: `${"Confirme su contrase\u00f1a"}`,
                  validate: (value, fromValues) =>
                    fromValues.password === value ||
                    "Las contrase\u00f1as son diferentes",
                })}
              />
            </label>
            <ButtonPrimary type="submit" loading={res.isLoading}>Continuar</ButtonPrimary>
          </form>
  }

  const renderVerify = () => <VerifyCode 
    register={register}
    handleSubmit={handleSubmit}
    errors={errors}
    onSubmit={onSubmit}
    isLoading={resVerify.isLoading}
    email={email}
  />

  const forms = [renderRegister,renderVerify]
  
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Registro
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}
          {forms[idx]()}
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            ¿Ya tiene una cuenta? {` `}
            <Link className="font-semibold hover:underline" to="/login">
              Iniciar sesión
            </Link>
          </span>
        </div>
      </div>
      <Toaster/>
    </div>
  );
};

export default PageSignUp;
