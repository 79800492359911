import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

const CheckPassword = ({ password = "" }: { password?: string }) => {
  const isGreaterThanSix = password.length >= 6;
  const isWithDigit = /\d/.test(password);
  const isWithUpperCase = /[A-Z]|Ñ/.test(password);
  const isWithLowerCase = /[a-z]|ñ/.test(password);
  const isWithSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
    password
  );
  return (
    <ul className="mt-2">
      {[
        {
          text: "Al menos 6 caracteres",
          isCheck: isGreaterThanSix,
        },
        {
          text: "Mínimo una mayúscula",
          isCheck: isWithUpperCase,
        },
        {
          text: "Mínimo una minúscula",
          isCheck: isWithLowerCase,
        },
        {
          text: "Mínimo un número",
          isCheck: isWithDigit,
        },
        {
          text: "Mínimo un caracter especial (!@#$%^&*)",
          isCheck: isWithSpecialChars,
        },
      ].map(({ text, isCheck }) => {
        return (
          <li
            key={`validate-password-${text}`}
            className={`flex gap-1 items-center `}
          >
            {isCheck ? (
              <CheckIcon
                className={` max-w-5 min-w-5 max-h-5 min-h-5 text-green-500`}
              />
            ) : (
              <XMarkIcon
                className={` max-w-5 min-w-5 max-h-5 min-h-5 text-red-500`}
              />
            )}
            <img
              //   src={isCheck ? Check : UnCheck}
              className=" max-w-4 min-w-4 max-h-4 min-h-4"
            />
            <p >{text}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default CheckPassword;
