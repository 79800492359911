import {configureStore} from '@reduxjs/toolkit';
import {baseApi} from './api/baseApi';
import {collegeSlice, userSlice} from './slices';
import { College, User } from 'data/data';
import { WP_REST_API_User, WP_User_Data } from 'wp-types';

export type RootStore = {
  college:{
    college:College
  },
  user:{
    user:User
  }
}

export const store = configureStore ({
  reducer: {
    college: collegeSlice.reducer,
    user: userSlice.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware ().concat (baseApi.middleware),
});
