import React, { FC, useState } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown, { AvatarIcon } from "./AvatarDropdown";
import Navigation from "shared/Navigation/Navigation";
import CartDropdown from "./CartDropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useCollege, useUser } from "store/utils/hooks";
import Button from "shared/Button/Button";
import Heading from "components/Heading/Heading";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [showSearchForm, setShowSearchForm] = useState(false);
  const navigate = useNavigate();
  const user = useUser();
  const college = useCollege()
  const img = college.logo;

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          navigate(`/page-search?search=${inputRef.current?.value}`);
          setShowSearchForm(false);
        }}
        className="flex-1 py-2 text-slate-900 dark:text-slate-100"
      >
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            ref={inputRef}
            type="text"
            placeholder="Escriba y presione enter"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
          />
          <button type="button" onClick={() => setShowSearchForm(false)}>
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex justify-between items-center">
        <div className="flex items-center lg:hidden flex-1">
          <MenuBar />
        </div>

        <div className="py-1 flex items-center gap-4 w-full">
          <Link to={"/"}>
            <img src={img} className="max-h-16 flex-shrink-0"/>
          </Link>
          {/* {user && (
            <Link to={"/"}>
              <Heading
                className=""
                fontClass="text-xl capitalize font-semibold"
              >
                Bienvenido, {user.displayName || user.username}
              </Heading>
            </Link>
          )} */}
        </div>

        <div className="hidden w-full lg:flex justify-center mx-4">
          {showSearchForm ? (
            renderSearchForm()
          ) : (
            <ul className="flex gap-8 text-lg items-center">
              <li className="group">
                <NavLink to={"/"}>Inicio</NavLink>
                <div className="group-hover:w-full w-[0px] transition-all h-[2px] bg-gray-900"></div>
              </li>
              <li className="group">
                <NavLink to={"/page-search?search="}>Productos</NavLink>
                <div className="group-hover:w-full w-[0px] transition-all h-[2px] bg-gray-900"></div>
              </li>
              <li className="group relative">
                <NavLink to={"/faqs"}>Faqs</NavLink>
                <div className="group-hover:w-full w-[0px] transition-all h-[2px] bg-gray-900"></div>
              </li>
              {!user && <>
                <li className="group whitespace-nowrap">
                  <NavLink to={"/login"}>Iniciar sesión</NavLink>
                  <div className="group-hover:w-full w-[0px] transition-all h-[2px] bg-gray-900"></div>
                </li>
                <li className="group whitespace-nowrap">
                  <NavLink to={"/signup"} className={'group-hover:no-underline underline'}>Crear cuenta</NavLink>
                  <div className="group-hover:w-full w-[0px] transition-all h-[2px] bg-gray-900"></div>
                </li>
              </>}
            </ul>
          )}
        </div>

        <div className="w-full flex items-center justify-end text-slate-700 dark:text-slate-100">
          {!showSearchForm && (
            <button
              className="hidden lg:flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center"
              onClick={() => setShowSearchForm(!showSearchForm)}
            >
              {renderMagnifyingGlassIcon()}
            </button>
          )}
          {!user ? (
            <button onClick={() => navigate("/login")} className="mx-4">
              <i className="las la-user-lock text-2xl"></i>
            </button>
          ) : (
            <AvatarDropdown />
          )}
          <CartDropdown />
        </div>
      </div>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
      <div className="container ">{renderContent()}</div>
    </div>
  );
};

export default MainNav2Logged;
