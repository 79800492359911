import { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useGetCollegeByUrlQuery, useGetCollegesQuery } from "store/api/baseApi";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import { College } from "data/data";
import { useDispatch } from "react-redux";
import { setCollege } from "store/slices";
import { _renderLoading } from "components/SectionSliderProductCard";

export interface CollegeSignUpProps {
  className?: string;
}

type FormValues = {
    password:string
}

const resolver: Resolver<FormValues> = async (values) => {
    return {
      values: values.password ? values : {},
      errors: !values.password
        ? {
            password: {
              type: "required",
              message: "El código del colegio es obligatorio",
            },
          }
        : {},
    }
  }

const CollegeSignUp: FC<CollegeSignUpProps> = ({ className = "" }) => {
  const url = window.location.origin
  const { data: college, isLoading, isSuccess } = useGetCollegeByUrlQuery(url);
  console.log({college})
  const [isPending, setIsPending] = useState<boolean>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccess) {
      if (isPending) {
        checkCode(getValues().password);
      }
      setIsPending(false);
    }
  }, [isSuccess]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm<FormValues>({resolver});
  console.log({errors},getValues())
  const onSubmit:SubmitHandler<FormValues> = ({password}) => {
    if (isPending === undefined) {
      setIsPending(true);
      return;
    } else if (isPending === false) {
      checkCode(password);
    }
  }
  function checkCode(password: string) {
    const isCorrect = college?.code === password
    if (!isCorrect) {
      setError("password", { message: "Código incorrecto" });
      return;
    }
    dispatch(setCollege(college));
  }
  console.log(college)
  return (
    <div
      className={`nc-CollegeSignUp  ${className}`}
      data-nc-id="CollegeSignUp"
    >
      <div className="container flex justify-center items-center h-screen ">
        <div className="max-w-md mx-auto space-y-6 ">
          {
            isLoading
            ? <div className="w-full flex justify-center">{_renderLoading()}</div>
            : <img src={college?.logo || "/icono.png"} alt="logo momoi" className="mx-auto h-24 pb-4" />
          }
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Introduce el código del colegio:
              </span>
              <Input
                type="password"
                className="mt-1"
                {...register("password")}
              />
            </label>
            {errors.password?.message && <span className="text-red-500">{errors.password?.message}</span>}
            <ButtonPrimary
              loading={isPending}
              disabled={isPending}
              type="submit"
            >
              Continuar
            </ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CollegeSignUp;
