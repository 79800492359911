import { ORDER_STATUS, OrderStatus } from "utils/get-order-status";

export const CAN_REFUND: OrderStatus[] = [
  ORDER_STATUS.SENDED,
  ORDER_STATUS.PARTIAL_SEND,
  ORDER_STATUS.COMPLETE,
  ORDER_STATUS.FULL_COMPLETE,
  // ORDER_STATUS.WAITING
];

export const BILL_STATUS: OrderStatus[] = [
  ORDER_STATUS.SENDED,
  ORDER_STATUS.PARTIAL_SEND,
  ORDER_STATUS.DEVOLUTION_REQUEST,
  ORDER_STATUS.FULL_COMPLETE,
  ORDER_STATUS.REFUNDED,
];

export const BILL_REFUND_STATUS: OrderStatus[] = [
  // ORDER_STATUS.FULL_COMPLETE,
  ORDER_STATUS.REFUNDED,
];

export const SEND_PRICE = 6;
