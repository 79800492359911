import { DialogContent, DialogTitle, Dialog, DialogActions } from "@mui/material"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import ButtonSecondary from "shared/Button/ButtonSecondary"
import ButtonClose from "shared/ButtonClose/ButtonClose"

const DialogAddStudent = ({open,hide,title="Confirmar",desc="¿Seguro desea realizar esta operación?",onConfirm}:{open:boolean,hide:()=>void,onConfirm:()=>void,title?:string, desc?:string}) => {
  return (
    <Dialog open={open} onClose={hide} >
      <div className="flex justify-between items-center">
      <DialogTitle>
       <h3 className="font-semibold">{title}</h3>
      </DialogTitle>
      <ButtonClose onClick={hide} className="mr-4"></ButtonClose>
      </div>
      <DialogContent>
          <p>{desc}</p>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={hide} type="button" className="w-full">Ir atrás</ButtonSecondary>
        <ButtonPrimary onClick={()=>{
          onConfirm()
          hide()
        }} type="button" className="w-full">Confirmar</ButtonPrimary>
      </DialogActions>
    </Dialog>
  )
}

export default DialogAddStudent