import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import HeaderFilterSearchPage, { Categories } from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import ProductCard from "components/ProductCard";
import { Category, CollegeProduct, PRODUCTS } from "data/data";
import { useProducts } from "store/utils/hooks";
import { useSearchParam } from "react-use";
import { useSearchParams } from "react-router-dom";
import { _renderLoading } from "components/SectionSliderProductCard";
import Nav from "shared/Nav/Nav";
import { Product } from "data/types";

export interface PageSearchProps {
  className?: string;
}

const isGreater = (a:Product,b:Product,order:string):number | boolean=>{
  if(order === 'updated_at'){
    return new Date(a[order])<new Date(b[order])
  }else if(order === 'created_at'){
    return new Date(a[order])<new Date(b[order])
  }else if(order === 'total_sales'){
    return Number(a[order])<Number(b[order])
  }else if(typeof a[order] === 'string' && typeof b[order] === 'string'){
    return a[order].localeCompare(b[order])
  }
  return a[order]>b[order]
}

const PageSearch: FC<PageSearchProps> = ({ className = "" }) => {
  const [products, isLoading] = useProducts(true);
  const [query, setQuery] = useSearchParams();
  const onChange = (event: any) => {
    const { name, value } = event?.target;
    setQuery({...Object.fromEntries(query), [name]: value });
  };
  const order = (products:CollegeProduct[]) => {
    if(!products) return 
    let transformProducts = [...products]
    if(query.has('category')){
      const category = query.get('category')
      console.log({category})
      transformProducts = transformProducts.filter((it)=>it.categories.map((it2)=>it2.id).includes(Number(category)))
    }
    if(query.has('order_by')){
      const order = query.get('order_by')
      transformProducts.sort((a,b)=>{
        const is = isGreater(a,b,order)
        if(typeof is === 'number'){
          return is
        }
        return is?1:-1
      })
    }
    return transformProducts
  }
  const filterProducts:(CollegeProduct[] | undefined) = order((products as CollegeProduct[])
  ?.filter((it) =>
    it.name
      .toLowerCase()
      .includes(query?.get("search")?.toLowerCase() || "")
  ))
  return (
    <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
      <div
        className={`nc-HeadBackgroundCommon h-24 2xl:h-28 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
        data-nc-id="HeadBackgroundCommon"
      />
      <div className="container">
        <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
          <form
            className="relative w-full"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <span className="sr-only">Search all icons</span>
              <Input
                className="shadow-lg border-0 dark:border"
                id="search-input"
                type="search"
                placeholder="Escriba la palabra clave"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                rounded="rounded-full"
                name="search"
                value={query.get("search") || ""}
                onChange={onChange}
              />
              <ButtonCircle
                className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                size=" w-11 h-11"
                type="submit"
              >
                <i className="las la-arrow-right text-xl"></i>
              </ButtonCircle>
              <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </label>
          </form>
        </header>
      </div>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>
          {/* FILTER */}
          <HeaderFilterSearchPage />
          <div className="flex gap-8">
          <Nav
            className="sm:space-x-2 flex-col gap-4"
            containerClassName="relative hidden md:flex flex w-full md:max-w-[300px] overflow-x-auto text-sm md:text-base hiddenScrollbar"
          >
            <Categories/>
          </Nav>
          {isLoading && <div className="w-full flex justify-center"> {_renderLoading()}</div>}
          {/* LOOP ITEMS */}
          <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-0">
            {filterProducts?.map((item, index) => (
                <ProductCard data={item} key={index} />
              ))}
          </div>
          </div>
          {/* PAGINATION */}
          {/* <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination />
            <ButtonPrimary loading>Show me more</ButtonPrimary>
          </div> */}
        </main>

        {/* === SECTION 5 === */}
        {/* <hr className="border-slate-200 dark:border-slate-700" />
        <SectionSliderCollections />
        <hr className="border-slate-200 dark:border-slate-700" /> */}

        {/* SUBCRIBES */}
        {/* <SectionPromo1 /> */}
      </div>
    </div>
  );
};

export default PageSearch;
