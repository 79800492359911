import { NoSymbolIcon, CheckIcon } from "@heroicons/react/24/outline";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { useCart } from "context/cart/cart.context";
import { Product, PRODUCTS } from "data/data";
import { CartItem } from "data/types";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import getEuro from "utils/getEuro";
import { normalizeAttrName } from "utils/normalizeAttr";
import { StockWarning } from "./GroupedDetailPage";
import { orderBy } from "lodash";

const CartPage = () => {
  const { items, total, addItemToCart, removeItemFromCart, clearItemFromCart } =
    useCart();
  const renderStatusSoldout = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <NoSymbolIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none">Sold Out</span>
      </div>
    );
  };

  const renderStatusInstock = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <CheckIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none">In Stock</span>
      </div>
    );
  };

  const renderProduct = (item: CartItem, index: number) => {
    const {
      image,
      price,
      name,
      attributes,
      itemTotal,
      quantity,
      maxCartAmount,
      parent_id: id,
      stock_quantity,
      limit_store,
    } = item;
    console.log({ item });
    return (
      <div
        key={index}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={image}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
          <Link
            to={`/product-detail?id=${id}`}
            className="absolute inset-0"
          ></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">
                  <Link to={`/product-detail?id=${id}`}>{name}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                  {orderBy(Object.keys(attributes)).map(
                    (it: string, idx: number) => {
                      return (
                        <>
                          <span className="capitalize">
                            {normalizeAttrName(it)}: {attributes[it]}
                          </span>
                          {Object.values(attributes).length - 1 !== idx && (
                            <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                          )}
                        </>
                      );
                    }
                  )}
                </div>
                <div>
                  {stock_quantity < quantity && (
                    <p className="text-red-400 text-xs">
                      En fabricación, el producto se enviará cuando las
                      cantidades vuelvan a estar disponibles
                    </p>
                  )}
                  {Boolean(limit_store) && (
                    <p className="text-red-400 text-xs">
                      Este producto está limitado a una compra por estudiante.
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:block text-center relative">
                <NcInputNumber
                  onIncrement={() => addItemToCart(item, 1)}
                  onDecrement={() => removeItemFromCart(item.id)}
                  defaultValue={quantity}
                  className="relative z-10"
                  max={maxCartAmount}
                />
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={itemTotal} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-end text-sm">
            {/* {Math.random() > 0.6
              ? renderStatusSoldout()
              : renderStatusInstock()} */}

            <a
              href="##"
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
              onClick={() => clearItemFromCart(item.id)}
            >
              <span>Eliminar</span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  console.log({ items });

  return (
    <div className="nc-CartPage">
      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-12 sm:mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Carrito de Compras
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="">
              Inicio
            </Link>
            {/* <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <Link to={"/#"} className="">
              Clothing Categories
            </Link> */}
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Carrito de compras</span>
          </div>
        </div>

        <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />

        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-[60%] xl:w-[55%] divide-y divide-slate-200 dark:divide-slate-700 ">
            {items.map((it: CartItem, idx: number) => renderProduct(it, idx))}
          </div>
          <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
          <div className="flex-1">
            <div className="sticky top-28">
              {/* <h3 className="text-lg font-semibold ">Order Summary</h3>
              <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                <div className="flex justify-between pb-4">
                  <span>Subtotal</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    $249.00
                  </span>
                </div>
                <div className="flex justify-between py-4">
                  <span>Shpping estimate</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    $5.00
                  </span>
                </div>
                <div className="flex justify-between py-4">
                  <span>Tax estimate</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    $24.90
                  </span>
                </div>
                <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                  <span>Order total</span>
                  <span>$276.00</span>
                </div>
              </div> */}
              <ButtonPrimary href="/checkout" className="mt-8 w-full">
                Comprar {getEuro(total)}
              </ButtonPrimary>
              {items?.some((it) => it?.quantity > it?.stock_quantity) && (
                <StockWarning noStock={false} />
              )}
              {/* <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
                <p className="block relative pl-5">
                  <svg
                    className="w-4 h-4 absolute -left-1 top-0.5"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 8V13"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.9945 16H12.0035"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Learn more{` `}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="##"
                    className="text-slate-900 dark:text-slate-200 underline font-medium"
                  >
                    Taxes
                  </a>
                  <span>
                    {` `}and{` `}
                  </span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="##"
                    className="text-slate-900 dark:text-slate-200 underline font-medium"
                  >
                    Shipping
                  </a>
                  {` `} infomation
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CartPage;
