import { useDispatch } from "react-redux";
import { useGetCollegeByUrlQuery, useGetCollegesQuery } from "../store/api/baseApi";
import { useEffect } from "react";
import { setCollege, setUser } from "../store/slices";
import { useGetCustomerQuery } from "../store/api/customersApi";
import { College } from "data/data";

function saveSessionsIds (college:number, user:number) {
  localStorage.setItem ('college', college.toString());
  localStorage.setItem ('user', user.toString());
}

function removeSessionsIds () {
  localStorage.removeItem ('college');
  localStorage.removeItem ('user');
}

function getSessionsIds ():{
  college:string,
  user:string
} | undefined {
  const college = localStorage.getItem ('college');
  const user = localStorage.getItem ('user');
  if (college && user) {
    return {college, user};
  }
}

function useLoadSessions():{
  isLoading:boolean
  }{
    const user = localStorage.getItem ('user');
    const dispatch = useDispatch();
    const url = window.location.origin
    const { data: college, isLoading:isLoadingCollege } = useGetCollegeByUrlQuery(url);
    const {data:customer, isLoading:isLoadingCustomer} = useGetCustomerQuery(user);
    useEffect(()=>{
        if(college){
            dispatch(setCollege(college))
        }
    },[college])
    useEffect(()=>{
        if(customer){
            dispatch(setUser(customer))
        }
    },[customer])
    return {isLoading: (isLoadingCollege || isLoadingCustomer)}
}

export {saveSessionsIds, removeSessionsIds, getSessionsIds,useLoadSessions};
