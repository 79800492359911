import { Student } from "data/data";
import React, { FC, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Radio from "shared/Radio/Radio";
import DialogAddStudent from "./DialogAddStudent";
interface Props {
  isActive: boolean;
  students:Student[];
  setStudents:React.Dispatch<React.SetStateAction<Student[]>>;
  student:number;
  setStudent:React.Dispatch<React.SetStateAction<number>>;
}

const Students: FC<Props> = ({
  isActive,
  students,
  setStudents,
  student,
  setStudent,
}) => {
    const [isAddStudentOpen,setIsAddStudentOpen] = useState(false)
  const renderShippingAddress = () => {
    return (
      <div className={`border border-slate-200 dark:border-slate-700 rounded-xl ${students.length === 0?'border-red-500 border-1 transition-colors':''}`}>
        <div className="p-6 flex flex-col sm:flex-row items-start">
          <span className="hidden sm:block">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
          </svg>

          </span>

          <div className="sm:ml-8">
            <h3 className=" text-slate-700 dark:text-slate-300 flex ">
              <span className="uppercase">Estudiante</span>
              <svg
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </h3>
            <div className="font-semibold mt-1 text-sm">
              <span className="">
                {students[student]?.name}
              </span>
            </div>
          </div>
          <ButtonSecondary
            sizeClass="py-2 px-4 "
            fontSize="text-sm font-medium"
            className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
            onClick={()=>setIsAddStudentOpen(true)}
          >
            Agregar
          </ButtonSecondary>
        </div>
        <div
          className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
            isActive ? "block" : "hidden"
          }`}
        >
        <div 
        className="flex flex-wrap gap-4"
        >
            {students.map((it,idx)=>{
              return <Radio name="" id={it.id as any} onChange={(value)=>{
                setStudent(idx)
              }} checked={student===idx}  label={`${it.name}`}></Radio>
            })}
            {students.length === 0 && "No tienes ningún alumno dado de alta."}
        </div>
        <DialogAddStudent students={students} setStudents={setStudents} open={isAddStudentOpen} hide={()=>setIsAddStudentOpen(false)}/>

          {/* ============ */}
          {/* <div className="flex flex-col sm:flex-row pt-6">
            <ButtonPrimary
              className="sm:!px-7 shadow-none"
              onClick={onCloseActive}
            >
              Continuar
            </ButtonPrimary>
            <ButtonSecondary
              className="mt-3 sm:mt-0 sm:ml-3"
              onClick={onCloseActive}
            >
              Cerrar
            </ButtonSecondary>
          </div> */}
        </div>
      </div>
    );
  };
  return renderShippingAddress();
};

export default Students;
