import { FC, useState } from "react";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useCollege } from "store/utils/hooks";
import {
  useGetCodeForgotPasswordMutation,
  useResendCodeMutation,
  useUpdateUserMutation,
} from "store/api/baseApi";
import toast from "react-hot-toast";
import PrettyDivider from "shared/PrettyDivider";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface PageLoginProps {
  onSubmit: any;
  handleSubmit: any;
  errors: any;
  register: any;
  isLoading: boolean;
  email?: string;
}

type FormValues = {
  email: string;
  password: string;
  code: string;
  confirm_password: string;
};

const VerifyCode: FC<PageLoginProps> = ({
  onSubmit,
  handleSubmit,
  errors,
  register,
  isLoading,
  email,
}) => {
  let cifEmail: string | string[] | undefined = email?.split("@");
  cifEmail =
    cifEmail && cifEmail[0] + "@" + new Array(cifEmail[1].length + 1).join("*");
  const [resendCode, { isLoading: isResendingCode }] = useResendCodeMutation();
  return (
    <form
      className="grid grid-cols-1 gap-6"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <p className="text-justify">
        Hemos enviado un código al correo electrónico <b>{cifEmail}</b> para la
        validación de su cuenta. De no estar en la bandeja de recibidos, busque
        en la carpeta de Spam.
      </p>
      <label className="block">
        <Input
          maxLength={4}
          placeholder="Código: XXXX"
          className="mt-1"
          error={errors.code?.message}
          {...register("code", {
            required: "Escriba el código enviado al correo.",
          })}
        />
      </label>
      <ButtonPrimary type="submit" loading={isLoading}>
        Confirmar
      </ButtonPrimary>
      <ButtonSecondary
        onClick={async () => {
          try {
            const res = await resendCode(email);
            toast.success(res.data.message);
          } catch (error) {
            toast.error(error.message);
          }
        }}
        type="button"
        loading={isResendingCode}
      >
        Reenviar código
      </ButtonSecondary>
    </form>
  );
};

export default VerifyCode;
