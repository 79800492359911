import React, { FC } from "react";
import { useUser } from "store/utils/hooks";
import getEuro from "utils/getEuro";

export interface PricesProps {
  className?: string;
  price?: number;
  refund_price?:number;
  contentClass?: string;
  price_html?:string
}

const Prices: FC<PricesProps> = ({
  className = "",
  price = 33,
  refund_price,
  price_html,
  contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium",
}) => {
  const user = useUser();
  const hasRefund = refund_price || refund_price === 0
  if (!user){
    return <p className="font-medium">Inicia sesión para ver los precios</p>
  }
  return (
    <div className={className}>
      <div
        className={`flex items-center border-2 border-green-500 rounded-lg ${contentClass}`}
      >
        {price_html && price_html.length !== 0
        ?<div className={`text-green-500 !leading-none`} dangerouslySetInnerHTML={{__html:price_html}}>
          </div>
          :<>
          <span className={`text-green-500 !leading-none ${hasRefund ?"line-through":""}`}>
            {getEuro(price)}
          </span>
          {hasRefund && <span className="ml-2 text-green-500 !leading-none">
            {hasRefund}
          </span>}
        </>}
      </div>
    </div>
  );
};

export default Prices;
