import SectionHero from "components/SectionHero/SectionHero";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionHero3 from "components/SectionHero/SectionHero3";
import image from "images/promo3.png";

export default () => {
  return (
    <main className="container py-10">
      <SectionHero
        title="¡Solicitud de Devolución Recibida!"
        subtitle="En Breve recibirá un email con la información de su Devolución."
        img={image}
        bg={'#edf7ea'}
      />
    </main>
  );
};
