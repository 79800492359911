export const colors = {
    black: '#000000',
    silver: '#C0C0C0',
    gray: '#808080',
    white: '#FFFFFF',
    maroon: '#800000',
    red: '#FF0000',
    purple: '#800080',
    fuchsia: '#FF00FF',
    green: '#008000',
    lime: '#00FF00',
    olive: '#808000',
    navy: '#000080',
    blue: '#0000FF',
    teal: '#008080',
    aqua: '#00FFFF',
    orange: '#FFA500',
    brown: '#A52A2A',
    yellow: '#FFD700',
    coral: '#FF7F50',
  
    //expannol
    negro: '#000000',
    plata: '#C0C0C0',
    gris: '#808080',
    blanco: '#FFFFFF',
    granate: '#800000',
    rojo: '#FF0000',
    morado: '#800080',
    fucsia: '#FF00FF',
    verde: '#008000',
    lima: '#00FF00',
    oliva: '#808000',
    amarillo: '#FFFF00',
    // navy: '#000080',
    azul: '#0000FF',
    // teal: '#008080',
    // aqua: '#00FFFF',
    naranja: '#FFA500',
    cafe: '#A52A2A',
    amarillento: '#FFD700',
    // coral: '#FF7F50',
  };
  