import React from "react";
import { cartReducer, initialState } from "./cart.reducer";
import { getItem } from "./cart.utils";
import { useLocalStorage } from "react-use";
import { useSelector } from "react-redux";
import { useUser } from "store/utils/hooks";
import { CartItem } from "data/types";

export const cartContext = React.createContext(undefined);

cartContext.displayName = "CartContext";

export const useCart = ():{
  addItemToCart:((item:CartItem, quantity:number)=>void),
  removeItemFromCart:(id:number)=>void,
  clearItemFromCart:(id:number)=>void,
  getItemFromCart:(id:number)=>void,
  isInCart:(id:number)=>boolean,
  resetCart:()=>void,
  total:number,
  items:CartItem[],
  totalItems:number,
} => {
  const context = React.useContext(cartContext);
  if (context === undefined) {
    throw new Error(`useCart must be used within a CartProvider`);
  }
  return context;
};

export const CartProvider = (props:any) => {
  const [savedCart, saveCart] = useLocalStorage(
    `chawkbazar-cart`,
    JSON.stringify(initialState)
  );
  const user = useUser();
  console.log({ user });
  const staticEmptyCart = { items: [], total: 0, isEmpty: true, totalItems: 0 };
  const [state, dispatch] = React.useReducer(
    cartReducer,
    JSON.parse(savedCart || "")
  );

  React.useEffect(() => {
    saveCart(JSON.stringify(state));
  }, [state, saveCart]);

  const addItemToCart = (item:CartItem, quantity:number) => {
    if(getItemFromCart(item.id) && item.limit_store){
      return
    }
    dispatch({ type: "ADD_ITEM_WITH_QUANTITY", item, quantity });
  };
  const removeItemFromCart = (id:number) =>
    dispatch({ type: "REMOVE_ITEM_OR_QUANTITY", id });
  const clearItemFromCart = (id:number) => dispatch({ type: "REMOVE_ITEM", id });
  const isInCart = (id:number) => !!getItem(state.items, id);
  const getItemFromCart = (id:number) => getItem(state.items, id);
  const resetCart = () => dispatch({ type: "RESET_CART" });
  // const inStock=()=>{}
  const cart = user ? state : staticEmptyCart;
  const value = React.useMemo(
    () => ({
      ...cart,
      addItemToCart,
      removeItemFromCart,
      clearItemFromCart,
      getItemFromCart,
      isInCart,
      resetCart,
    }),
    [state, user]
  );
  return <cartContext.Provider value={value} {...props} />;
};
