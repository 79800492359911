import { baseApi } from "./baseApi"

const BASE = `settings`

const politicsApi = baseApi.injectEndpoints({
    endpoints:(builder)=>({
        getPolitics:builder.query({
            query:()=>BASE,
            transformResponse:(req)=>{
                return req.data
            }
        })
    })
})

export const {useGetPoliticsQuery} = politicsApi