import { Attribute, CartItem, Product } from "data/types";

export function generateCartItem(
  item: Product,
  attributes: { [key: string]: string }
): CartItem {
  const {
    id,
    name,
    slug,
    image,
    price,
    sale_price,
    parent_id,
    stock_quantity,
    pivot,
    limit_store,
    store_without_stock,
  } = item;
  return {
    // id: Object.keys(attributes).length
    //   ? `${id}.${Object.values (attributes).join ('.')}`
    //   : id,
    name,
    slug,
    image,
    limit_store,
    stock_quantity,
    price: sale_price ? sale_price : price,
    attributes,
    id,
    parent_id: parent_id || id,
    pivot_parent_id: pivot?.parent_id,
    maxCartAmount: Boolean(limit_store)
      ? 1
      : !Boolean(store_without_stock)
      ? Number(stock_quantity)
      : undefined,
  };
}
