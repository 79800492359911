import {baseApi} from './baseApi';

const BASE_URL = 'auth/profile';

const authApi = baseApi.injectEndpoints ({
  endpoints: builder => ({
    login: builder.mutation ({
      query: body => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
    }),
    updatePassword: builder.mutation({
      query:(body)=>({
        url:`auth/update-password`,
        body,
        method:"PUT"
      })
    }),
    getCustomer: builder.query({
      query:()=>`auth/profile`,
      transformResponse:(data)=>data.data
    }),
    signUp: builder.mutation ({
      query: body => ({
        url: `auth/register`,
        method: 'POST',
        body,
      }),
    }),
    updateBilling: builder.mutation ({
      query: (body) => ({
        url: `${BASE_URL}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {useLoginMutation, useUpdatePasswordMutation, useGetCustomerQuery, useSignUpMutation, useUpdateBillingMutation} = authApi;
