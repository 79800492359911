import React, { FC, memo, useEffect, useMemo, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import AccordionInfo, { RichTextFormat } from "./AccordionInfo";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { CollegeProduct } from "data/data";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import SectionPromo2 from "components/SectionPromo2";
import ModalViewAllReviews from "./ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";
import { useProducts, useUser } from "store/utils/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getVariations, getVariationsByVariations } from "utils/getVariations";
import {
  useGetProductQuery,
  useGetVariationsQuery,
} from "store/api/productsApi";
import { Product } from "data/types";
import { ProductAttributes } from "components/ProductsAttributes";
import { generateCartItem } from "utils/generateCartItems";
import { useCart } from "context/cart/cart.context";
import NcImage from "shared/NcImage/NcImage";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import parse from "html-react-parser";

export interface ProductDetailPageProps {
  className?: string;
}

const SingleProduct = ({
  data,
  setProduct,
  setIsSelected,
}: {
  data: CollegeProduct;
  setProduct: any;
  setIsSelected: any;
}) => {
  const { data: productVariations, isLoading: isLoadingVariations } =
    useGetVariationsQuery(data?.id, { skip: data?.type !== "variable" });
  const [isShowGuide, setIsShowGuide] = React.useState(false);
  const [attributes, setAttributes] = useState<{ [key: string]: string }>({});
  const variations = getVariationsByVariations(productVariations);
  useEffect(() => {
    if (data.type === "variable") {
      const product =
        Object.keys(variations).length &&
        productVariations?.find((it: Product) =>
          it.attributes.every(
            ({ name, terms }) => attributes[name] === terms[0].name
          )
        );
      setProduct?.({ ...product, pivot: data.pivot });
    }
  }, [attributes, variations, data]);
  const hideSizeGuide = () => {
    setIsShowGuide(false);
  };

  const showSizeGuide = () => {
    setIsShowGuide(true);
  };
  function handleAttribute(attribute: { [key: string]: string }) {
    setAttributes((prev) => ({
      ...prev,
      ...attribute,
    }));
  }
  return (
    <div>
      <h4 className="font-semibold text-xl border-b-2 mt-4">{data?.name}</h4>
      {variations && Object.keys(variations).length !== 0 && (
        <div className={`mt-4`}>
          {Object.keys(variations).map((variation) => {
            return (
              <ProductAttributes
                key={variation}
                title={variation}
                attributes={variations[variation]}
                active={attributes[variation]}
                onClick={handleAttribute}
              />
            );
          })}
        </div>
      )}
      <Dialog open={isShowGuide} onClose={hideSizeGuide}>
        <div className="flex justify-between items-center">
          <DialogTitle>
            <b>Guía de Tallas:</b> {data?.name}
          </DialogTitle>
          <ButtonClose onClick={hideSizeGuide} className="mr-4"></ButtonClose>
        </div>
        <DialogContent>
          <NcImage src={data?.size_guide} />
        </DialogContent>
      </Dialog>
      {data?.size_guide && (
        <div className="pb-3 border-b border-gray-300">
          <p
            onClick={showSizeGuide}
            className="font-semibold transition-all underline hover:opacity-50 cursor-zoom-in hover:no-underline"
          >
            Guía de tallas
          </p>
        </div>
      )}
    </div>
  );
};

export const WarningIcon = () => (
  <svg
    className="w-4 h-4 absolute -left-1 top-0.5"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8V13"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9945 16H12.0035"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const StockWarning = ({
  short,
  noStock,
}: {
  short?: boolean;
  noStock?: boolean;
}) => {
  return (
    <div className="mt-5 text-sm text-red-500 dark:text-red-400 flex items-center justify-center">
      <p className="block relative pl-5">
        <WarningIcon />
        {short
          ? "No existe disponibilidad actualmente para su pedido, puede que demore en llegar su envío."
          : noStock
          ? `Este producto se encuentra en fabricación`
          : "¡Atención! Si desea comprar una cantidad mayor a la disponible, tenga en cuenta que el envío puede demorar más ya que necesitaremos fabricar algunos productos."}
      </p>
    </div>
  );
};

export const Warning = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div className="text-sm text-red-500 dark:text-red-400 flex items-center justify-center">
      <p className="block relative pl-5">
        <WarningIcon />
        {children}
      </p>
    </div>
  );
};

const GroupedDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  // const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
  // const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];
  const [params] = useSearchParams();
  const products = useProducts() as CollegeProduct[];
  const { data: apiProduct } = useGetProductQuery(Number(params.get("id")));
  const user = useUser();
  const navigate = useNavigate();
  const { items, addItemToCart } = useCart();
  const data = useMemo(() => {
    return (
      (products || apiProduct) && {
        ...(products?.find((it) => it.id === Number(params.get("id"))) || {}),
        ...(apiProduct || {}),
      }
    );
  }, [products, apiProduct, params]);
  const { data: productVariations, isLoading: isLoadingVariations } =
    useGetVariationsQuery(params.get("id"));
  const [attributes, setAttributes] = useState<{ [key: string]: string }>({});
  const variations = getVariations(data?.attributes);
  // const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
    useState(false);
  const product = Object.keys(variations).length
    ? productVariations?.find((it: Product) =>
        it.attributes.every(
          ({ name, terms }) => attributes[name] === terms[0].name
        )
      ) || data
    : data;
  const [productsForSale, setProductsForSale] = useState({});
  const isInCart =
    data &&
    items.some(({ pivot_parent_id, parent_id, id }) => {
      if (data.type === "grouped") {
        return data.id === pivot_parent_id;
      }
      if (data.type === "variable") {
        return data.id === parent_id;
      }
      return data.id === id;
    });
  const childrens = useMemo(() => {
    return data?.childrens.map((it) => (
      <SingleProduct
        data={it}
        setProduct={(product) => {
          setProductsForSale((prev) => ({
            ...prev,
            [it.id]: product,
          }));
        }}
      />
    ));
  }, [data]);
  const isSelected = Object.values(productsForSale).every(
    (it) => it !== undefined
  );

  useEffect(() => {
    setQualitySelected(1);
  }, [params]);
  useEffect(() => {
    let simples = data?.childrens?.filter((it) => it.type === "simple");
    simples?.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
    setProductsForSale((prev) => ({
      ...prev,
      ...simples,
    }));
  }, [data]);
  const addToCart = () => {
    Object.values(productsForSale).forEach((it) => {
      const item = generateCartItem(
        { ...data, ...it, limit_store: data.limit_store },
        it.attributes?.reduce((acc, curr) => {
          acc[curr.name] = curr.terms?.[0]?.name;
          return acc;
        }, {}) || {}
      );
      addItemToCart(item, qualitySelected);
    });
    notifyAddTocart();
  };
  const notifyAddTocart = () => {
    const isOutOfStock = qualitySelected > (product as Product).stock_quantity!;
    const item = generateCartItem(
      { ...data, ...product, isOutOfStock, name: data ? data.name : "" },
      attributes
    );
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={product?.image ? product?.image : ""}
          qualitySelected={qualitySelected}
          show={t.visible}
          name={item.name}
          attributes={item.attributes}
          price={
            Object.values(productsForSale).reduce((acc, curr) => {
              acc += curr.sale_price || curr.price || 0;
              return acc;
            }, 0) * qualitySelected
          }
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl sm:text-3xl font-semibold">{data?.name}</h2>
          {data?.short_description && (
            <RichTextFormat className="mt-4">
              {parse(data?.short_description)}
            </RichTextFormat>
          )}
          {/*  */}
          {Array.isArray(childrens) && childrens}

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}
            {isSelected && (
              <Prices
                contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
                price={
                  Object.values(productsForSale).reduce((acc, curr) => {
                    acc += curr.sale_price || curr.price || 0;
                    return acc;
                  }, 0) * qualitySelected
                }
              />
            )}

            {/* <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div> */}

            <div className="flex items-center">
              {/* {(product as Product)?.stock_quantity || "No hay"} disponibles */}
              {/* <a
                href="#reviews"
                className="flex items-center text-sm font-medium"
              >
                <StarIcon className="w-5 h-5 pb-[1px] text-yellow-400" />
                <div className="ml-1.5 flex">
                  <span>4.9</span>
                  <span className="block mx-2">·</span>
                  <span className="text-slate-600 dark:text-slate-400 underline">
                    142 reviews
                  </span>
                </div>
              </a>
              <span className="hidden sm:block mx-2.5">·</span>
              <div className="hidden sm:flex items-center text-sm">
                <SparklesIcon className="w-3.5 h-3.5" /> */}
              {/* <span className="ml-1 leading-none">{status}</span> */}
              {/* </div> */}
            </div>
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        {/* <div className="">{renderVariants()}</div>
        <div className="">{renderSizeList()}</div> */}

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              defaultValue={qualitySelected}
              onChange={setQualitySelected}
              max={data?.limit_store ? 1 : undefined}
            />
          </div>
          <ButtonPrimary
            className="flex-1 flex-shrink-0"
            // onClick={() => {}}
            disabled={!isSelected || (isInCart && data?.limit_store)}
            onClick={user ? addToCart : () => navigate("/login")}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Agregar al carrito</span>
          </ButtonPrimary>
        </div>
        <div className="space-y-2">
          {productsForSale &&
            !!Object.keys(productsForSale).length &&
            Object.values(productsForSale).some(
              (it) => (it as Product)?.stock_quantity < qualitySelected
            ) &&
            isSelected && (
              <StockWarning
                noStock={Object.values(productsForSale).some(
                  (it) => (it as Product)?.stock_quantity === 0
                )}
              />
            )}
          {Boolean(data?.limit_store) && (
            <Warning>
              Este producto está limitado a una compra por estudiante.
            </Warning>
          )}
        </div>

        {/*  */}
        <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>
        {/* ---------- 5 ----------  */}
        {data && (
          <AccordionInfo
            data={[
              {
                name: "Envíos y Devoluciones",
                content: data?.description,
              },
              {
                name: "Normas de lavado y secado",
                content: data?.instructions,
              },
            ]}
          />
        )}

        {/* ---------- 6 ----------  */}
        {/* <div className="hidden xl:block">
          <Policy />
        </div> */}
      </div>
    );
  };

  return (
    <div className={`nc-ProductDetailPage ${className}`}>
      {/* MAIn */}
      <main className="container mt-5 lg:mt-11">
        <div className="lg:flex">
          {/* CONTENT */}
          <div className="w-full lg:w-[55%] ">
            {/* HEADING */}
            <div className="relative">
              <div className="aspect-w-16 aspect-h-16">
                <img
                  src={product?.image}
                  className="w-full rounded-2xl object-cover"
                  alt="product detail 1"
                />
              </div>
              {/* {renderStatus()} */}
              {/* META FAVORITES */}
              {/* <LikeButton className="absolute right-3 top-3 " /> */}
            </div>
            {/* <div className="grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
              {product?.image
                .map((item: { src: string }, index: number) => {
                  return (
                    <div
                      key={index}
                      className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-16"
                    >
                      <img
                        src={item.src}
                        className="w-full rounded-2xl object-cover"
                        alt="product detail 1"
                      />
                    </div>
                  );
                })}
            </div> */}
          </div>

          {/* SIDEBAR */}
          <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
            {renderSectionContent()}
          </div>
        </div>

        {/* DETAIL AND REVIEW */}
        <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
          {/* <div className="block xl:hidden">
            <Policy />
          </div> */}

          {/* {renderDetailSection()} */}

          <hr className="border-slate-200 dark:border-slate-700" />

          {/* {renderReviews()} */}

          {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

          {/* OTHER SECTION */}
          <SectionSliderProductCard
            heading="Otros productos"
            subHeading=""
            headingFontClassName="text-2xl font-semibold"
            headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
          />

          {/* SECTION */}
          <div className="pb-20 xl:pb-28 lg:pt-14">
            <SectionPromo2 />
          </div>
        </div>
      </main>

      {/* MODAL VIEW ALL REVIEW */}
      <ModalViewAllReviews
        show={isOpenModalViewAllReviews}
        onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
      />
    </div>
  );
};

export default GroupedDetailPage;
