import React, { useEffect, useRef } from "react";

const PaymentForm = ({ paymentParams }) => {
  const formRef = useRef();
  const {
    Ds_SignatureVersion,
    Ds_MerchantParameters,
    Ds_Signature,
    Ds_Titular,
    Ds_MerchantCurrency,
    Ds_Amount,
    form_action,
  } = paymentParams;

  useEffect(() => {
    paymentParams && formRef.current.submit();
  }, [paymentParams]);

  return (
    <form name="from" action={form_action} method="POST" ref={formRef}>
      <input type="hidden" name="Ds_Titular" value={Ds_Titular} />
      <input
        type="hidden"
        name="Ds_MerchantCurrency"
        value={Ds_MerchantCurrency}
      />
      <input type="hidden" name="Ds_Amount" value={Ds_Amount} />
      <input
        type="hidden"
        name="Ds_SignatureVersion"
        value={Ds_SignatureVersion}
      />
      <input
        type="hidden"
        name="Ds_MerchantParameters"
        value={Ds_MerchantParameters}
      />
      <input type="hidden" name="Ds_Signature" value={Ds_Signature} />
    </form>
  );
};

export default PaymentForm;
