import { FC, createRef, useEffect, useRef } from "react";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  CircleMarker,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { useCollege } from "store/utils/hooks";
import { useSendEmailMutation } from "store/api/baseApi";
import { useForm } from "react-hook-form";
import { WooCommerceError } from "data/data";
import toast from "react-hot-toast";

const housingIcon = new Icon({
  iconUrl: "https://img.icons8.com/color/48/marker--v1.png",
  iconSize: [32, 32], // size of the icon
  iconAnchor: [16, 16], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -15], // point from which the popup should open relative to the iconAnchor
});

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890",
  },
];

const Map = () => {
  const college = useCollege();
  const position = { lat: college?.latitud || 0, lng: college?.longitud || 0 };
  return (
    <MapContainer
      center={position}
      zoom={7}
      scrollWheelZoom={false}
      className="w-full h-full"
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={position} icon={housingIcon}>
        <Popup>{college.name}</Popup>
      </Marker>
    </MapContainer>
  );
};

type FormValues = {
  nombre: string;
  email: string;
  mensaje: string;
};

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [send, res] = useSendEmailMutation();
  const college = useCollege();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();
  useEffect(() => {
    if (res.isError) {
      setError("mensaje", {
        message: (res.error as WooCommerceError).data.message,
      });
    } else if (res.isSuccess) {
      toast.success("Mensaje enviado");
    }
  }, [res]);
  function onSubmit(data: any) {
    send({ ...data, to: college?.email });
  }
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <div className="container mb-20">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contacto
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
            <div className="space-y-8 h-56 md:h-full !z-[1]">
              {/* {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))} */}
              {/* <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div> */}
              <Map />
            </div>

            <div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                className="grid grid-cols-1 gap-6"
              >
                <label className="block">
                  <Label>Nombre y apellidos</Label>

                  <Input
                    type="text"
                    className="mt-1"
                    error={errors.nombre?.message}
                    {...register("nombre", {
                      required: "El nombre es requerido",
                    })}
                  />
                </label>
                <label className="block">
                  <Label>Email</Label>

                  <Input
                    type="email"
                    placeholder="ejemplo@ejemplo.com"
                    className="mt-1"
                    error={errors.email?.message}
                    {...register("email", {
                      required: "El email es requerido",
                      pattern: {
                        value:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Entre un email válido",
                      },
                    })}
                  />
                </label>
                <label className="block">
                  <Label>Mensaje</Label>

                  <Textarea
                    {...register("mensaje", {
                      required: "El mensaje es requerida",
                    })}
                    className="mt-1"
                    rows={6}
                  />
                </label>
                <div>
                  <ButtonPrimary loading={res.isLoading} type="submit">
                    Enviar Mensaje
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative my-24 lg:my-32 py-24 lg:py-32">
          <BackgroundSection />
          <SectionPromo1 />
        </div>
      </div> */}
    </div>
  );
};

export default PageContact;
