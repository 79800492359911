import { DialogContent, DialogTitle, Dialog, DialogActions } from "@mui/material"
import { StudentSection } from "containers/AccountPage/AccountPage"
import { WarningIcon } from "containers/ProductDetailPage/GroupedDetailPage"
import { Student } from "data/data"
import React, { SetStateAction, useEffect, useState } from "react"
import Button from "shared/Button/Button"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import ButtonSecondary from "shared/Button/ButtonSecondary"
import ButtonClose from "shared/ButtonClose/ButtonClose"
import { v1 as uuid } from 'uuid'

const DialogAddStudent = ({open,hide,students,setStudents}:{open:boolean,hide:()=>void,students:Student[],setStudents:React.Dispatch<SetStateAction<Student[]>>}) => {
  const [student, setStudent] = useState<Student>({id:uuid()})
  return (
    <Dialog open={open} onClose={hide}>
      <div className="flex justify-between items-center">
      <DialogTitle>
        Agregar estudiante
      </DialogTitle>
      <ButtonClose onClick={hide} className="mr-4"></ButtonClose>
      </div>
      <DialogContent>
          <StudentSection planeFormat item={student} setItem={setStudent} className="px-4"/>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={hide} type="button" className="w-full">Cancelar</ButtonSecondary>
        <ButtonPrimary onClick={()=>{
            setStudents((prev)=>[...prev,student])
            setStudent({id:uuid()})
            hide()
        }} type="button" disabled={!student.name} className="w-full">Aceptar</ButtonPrimary>
      </DialogActions>
    </Dialog>
  )
}

export default DialogAddStudent