import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import SectionPromo2 from "components/SectionPromo2";

function PageHome() {
  
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* SECTION HERO */}
      <SectionHero2 />

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {/* SECTION */}
        <SectionSliderProductCard heading="Productos" subHeading=""/>
      </div>
      <div className="bg-[#FEFCE8] w-full">
        <div className="container relative space-y-24 py-24 lg:space-y-32 lg:py-32">
          <SectionPromo2 />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
