import Heading from 'components/Heading/Heading'
import { RichTextFormat } from 'containers/ProductDetailPage/AccordionInfo'
import React from 'react'
import { useGetPoliticsQuery } from 'store/api/politicsApi'
import parse from 'html-react-parser';
import { _renderLoading } from 'components/SectionSliderProductCard';

const Terms = () => {
    const {data, isLoading} = useGetPoliticsQuery(undefined) 
  return (
    <div className='container my-16'>
        <Heading desc={""}>
            Términos y Condiciones
        </Heading>
        {
          isLoading
          ?_renderLoading()
          :<RichTextFormat>
            {data?.terminos ? parse(data?.terminos) : "Aún no hay nada que mostrar"}
          </RichTextFormat>
        }
    </div>
  )
}

export default Terms