import { FC, useState } from "react";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useCollege } from "store/utils/hooks";
import {
  useGetCodeForgotPasswordMutation,
  useResendCodeMutation,
  useResetPasswordMutation,
  useUpdateUserMutation,
} from "store/api/baseApi";
import toast from "react-hot-toast";
import PrettyDivider from "shared/PrettyDivider";
import CheckPassword from "components/CheckPassword";
import { setUser as updateUser } from "store/slices";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface PageLoginProps {
  className?: string;
}

type FormValues = {
  email: string;
  password: string;
  code: string;
  confirm_password: string;
};

const PageForgotPassword: FC<PageLoginProps> = ({ className = "" }) => {
  const [getCode, { isLoading: isLoadingGetCode }] =
    useGetCodeForgotPasswordMutation();
  const [updatePassword, { isLoading: isLoadingUpdatePassword }] =
    useResetPasswordMutation();
  const [resendCode, { isLoading: isResendingCode }] = useResendCodeMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const college = useCollege();
  const [idx, setIdx] = useState<number>(0);
  const [user, setUser] = useState<number | undefined>();
  const [correctCode, setCorrectCode] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    getValues,
    watch,
  } = useForm<FormValues>();

  const password = watch("password");

  // useEffect(() => {
  //   if (res.isError) {
  //     setError("password", { message: (res.error as WooCommerceError).data.message });
  //   } else if (res.isSuccess) {
  //     dispatch(setUser({ billing: res.data.billing, ...res.data.data, ...res.data }));
  //     saveSessionsIds(college.id, res.data.data.id);
  //     navigate("/");
  //   }
  // }, [res]);

  async function onSubmit(data: any, object: any) {
    console.log({ object, idx });
    const { email, code, password, confirm_password } = data;
    if (idx === 0) {
      const res = (await getCode(email)) as {
        data: {
          status: boolean;
          message: string;
          code: string;
          user_id: number;
        };
      };
      if (res.error) {
        toast.error(res.error.data.message);
        return;
      }
      setIdx(1);
      setUser(res.data.data);
      setCorrectCode(res.data.data.pin);
      reset();
      toast.success(res.data.message);
    } else if (idx === 1) {
      if (code == correctCode) {
        setIdx(2);
        reset();
        toast.success("Entre la nueva contraseña");
      } else {
        toast.error("Código incorrecto");
      }
    } else {
      const res = (await updatePassword({
        pin: correctCode,
        password,
        email: user?.email,
        password_confirmation: confirm_password,
      })) as {
        data: object;
      };
      console.log({ res });
      if (!res.error) {
        navigate("/");
        dispatch(updateUser(res.data.data.user));
        localStorage.setItem("accessToken", res.data.data.accessToken);
        toast.success("Contraseña actualizada");
      } else {
        toast.error(res.error.data?.message);
      }
    }
  }

  // ENTER_EMAIL
  const renderEnterEmail = () => {
    return (
      <form
        className="grid grid-cols-1 gap-6"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">Email</span>
          <Input
            type="email"
            placeholder="ejemplo@ejemplo.com"
            className="mt-1"
            error={errors.email?.message}
            {...register("email", {
              required: "Escriba el correo o el nombre de usuario",
            })}
          />
        </label>
        <ButtonPrimary type="submit" loading={isLoadingGetCode}>
          Enviar código
        </ButtonPrimary>
      </form>
    );
  };

  // ENTER_CODE
  const renderEnterCode = () => {
    let cifEmail: string | string[] | undefined = user?.email?.split("@");
    cifEmail =
      cifEmail &&
      cifEmail[0] + "@" + new Array(cifEmail[1].length + 1).join("*");
    return (
      <form
        className="grid grid-cols-1 gap-6"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <p className="text-justify">
          Hemos enviado un código al correo electrónico <b>{cifEmail}</b> para
          la validación de su cuenta. De no estar en la bandeja de recibidos,
          busque en la carpeta de Spam.
        </p>
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">Código</span>
          <Input
            maxLength={4}
            placeholder="XXXX"
            className="mt-1"
            error={errors.code?.message}
            {...register("code", {
              required: "Escriba el código enviado al correo.",
            })}
          />
        </label>
        <ButtonPrimary type="submit" loading={isLoadingUpdatePassword}>
          Confirmar
        </ButtonPrimary>
        <ButtonSecondary
          onClick={async () => {
            try {
              const res = await resendCode(user?.email);
              toast.success(res.data.message);
            } catch (error) {
              toast.error(error.message);
            }
          }}
          type="button"
          loading={isResendingCode}
        >
          Reenviar código
        </ButtonSecondary>
      </form>
    );
  };

  const renderNewPassword = () => {
    return (
      <form
        className="grid grid-cols-1 gap-6"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            Nueva Contraseña
          </span>
          <Input
            type="password"
            placeholder="Contraseña"
            className="mt-1"
            error={errors.password?.message}
            {...register("password", {
              required: "Escriba la nueva contraseña.",
            })}
          />
        </label>
        <CheckPassword password={password} />
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            Confirmar Contraseña
          </span>
          <Input
            type="password"
            placeholder="Confirmar contraseña"
            className="mt-1"
            error={errors.confirm_password?.message}
            {...register("confirm_password", {
              required: `${"Confirme su contrase\u00f1a"}`,
              validate: (value, fromValues) =>
                fromValues.password === value ||
                "Las contrase\u00f1as son diferentes",
            })}
          />
        </label>
        <ButtonPrimary type="submit" loading={isLoadingUpdatePassword}>
          Actualizar
        </ButtonPrimary>
      </form>
    );
  };

  const steps = [renderEnterEmail, renderEnterCode, renderNewPassword];
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          ¿Olvidaste la contraseña?
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          {steps[idx]()}
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            ¿Ya tienes una cuenta? {` `}
            <Link className="font-semibold hover:underline" to={"/login"}>
              Inicia sesión
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageForgotPassword;
