import React, { FC, useEffect, useId, useRef } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import ProductCard from "./ProductCard";
import { CollegeProduct, Product, PRODUCTS } from "data/data";
import { useGetProductsQuery } from "store/api/productsApi";
import { useCollege } from "store/utils/hooks";
import NextPrev from "shared/NextPrev/NextPrev";

export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
}

export const _renderLoading = () => {
  return (
    <svg
      className="animate-spin -ml-1 mr-3 h-10 w-10 mx-auto"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="3"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const SectionSliderProductCard: FC<SectionSliderProductCardProps> = ({
  className = "",
  itemClassName = "",
  headingFontClassName,
  headingClassName,
  heading,
  subHeading = "REY backpacks & bags",
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  const college = useCollege();
  const {data = [], isLoading} = useGetProductsQuery(college.id);

  useEffect(() => {
    if (!sliderRef.current) {
      return () => {};
    }

    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [sliderRef, UNIQUE_CLASS, data]);

  return (
    <div className={`relative nc-SectionSliderProductCard ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading
          id="products"
          className={headingClassName}
          fontClass={headingFontClassName}
          rightDescText={subHeading}
        >
          {heading || `New Arrivals`}
        </Heading>
        {isLoading && _renderLoading()}
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {data.map((item:CollegeProduct, index:number) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <ProductCard data={item} />
              </li>
            ))}
          </ul>
          <div className="xl:absolute z-20 xl:-inset-x-20 max-w-6xl my-2 mx-auto top-full xl:top-1/2 transform xl:-translate-y-1/2 flex xl:justify-between glide__arrows">
            <NextPrev
              onlyPrev
              className="mr-1.5"
              btnClassName="w-8 h-8 sm:w-10 sm:h-10 "
            />
            <NextPrev
              onlyNext
              className="ml-1.5"
              btnClassName="w-8 h-8 sm:w-10 sm:h-10 "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderProductCard;
