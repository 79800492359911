import SectionHero3 from "components/SectionHero/SectionHero3";
import React from "react";
import image from "images/hero-right.png";

export default () => {
  return (
    <main className="container py-10">
      <SectionHero3
        title="Lo sentimos..."
        subtitle="Ha ocurrido un error durante su pedido"
        img={image}
      />
    </main>
  );
};
