import { Product } from "data/types";

export function getVariations (attributes:{slug:string, options:string[]}[]):{[key:string]:string[]} {
    if (!attributes) return {};
    const obj:{[key:string]:string[]} = {};
    attributes.forEach (({name, terms}) => {
      obj[name] = terms.map(({name})=>name);
    });
    return obj;
  }

export function getVariationsByVariations (variations:Product[]) {
  if(!variations) return {}
  return variations.reduce((acc,curr)=>{
    console.log(curr.status,curr.name)
    if(curr.status === 'draft') return acc
    curr.attributes.forEach (({name, terms}) => {
      if(acc[name]){
        acc[name] = [...new Set([...acc[name],terms[0].name])]
      }else{
        acc[name] = [terms[0].name];
      }
    });
    return acc
  },{})
}
  