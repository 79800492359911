import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {CK, CS, REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE} from 'constants/api';

export const ORDERS_TAG = 'ORDERS';

export const baseApi = createApi ({
  tagTypes: [ORDERS_TAG],
  reducerPath: 'api',
  baseQuery: fetchBaseQuery ({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, {getState}) => {
      headers.set ('Accept', 'application/json');
      const token = localStorage.getItem ('accessToken');
      if (token) {
        headers.set (REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE + token);
      }
      return headers;
    },
  }),

  endpoints: builder => ({
    updateUser: builder.mutation ({
      query: ({id, ...body}) => ({
        url: `wp/v2/users/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    verifyAccount: builder.mutation ({
      query: body => ({
        url: `auth/verify-account`,
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation ({
      query: body => ({
        url: `auth/reset-password`,
        method: 'POST',
        body,
      }),
    }),
    getCodeForgotPassword: builder.mutation ({
      query: email => ({
        url: 'auth/forgot-password',
        body: {email},
        method: 'POST',
      }),
    }),
    resendCode: builder.mutation ({
      query: email => ({
        url: 'auth/send-new-pin',
        body: {email},
        method: 'POST',
      }),
    }),
    getColleges: builder.query ({
      query: () => 'wp/v2/colegios',
      transformResponse: data => data.data,
    }),
    // {
    //     nombre:string
    //     email:string
    //     mensaje:string
    // }
    sendEmail: builder.mutation ({
      query: body => ({
        url: `/wp/v2/sendemail`,
        method: `POST`,
        body,
      }),
    }),
    getCollegeByUrl: builder.query ({
      query: url => ({
        url: `schools/find-by-url`,
        method: 'GET',
        params: {
          url,
        },
      }),
      transformResponse: req => req.data,
    }),
  }),
});

export const {
  useGetCollegesQuery,
  useResetPasswordMutation,
  useVerifyAccountMutation,
  useLazyGetCollegesQuery,
  useSendEmailMutation,
  useGetCollegeByUrlQuery,
  useGetCodeForgotPasswordMutation,
  useUpdateUserMutation,
  useResendCodeMutation,
} = baseApi;
