import {createSlice} from '@reduxjs/toolkit';

export const modalSlice = createSlice ({
  name: 'modal',
  initialState: {
    modal: null,
  },
  reducers: {
    setModal: (state, payload) => {
      state.modal = payload;
    },
  },
});

export const {setModal} = modalSlice.actions;
